'use strict';
var base = require('core/giftCertificate/giftCertificate');

var formErrors = require('../checkout/formErrors');
var formValidation = require('base/components/formValidation');
var cart = require('../cart/cart');
var recaptcha = require('core/components/recaptcha');

base.applyGiftCardAmount = function() {
  var $form = $('.GiftCertificateForm');
  var $amountInput = $form.find('#amount');

  $('body').on('click', '[data-giftcert-amount]', function() {
    $('[data-giftcert-amount]').removeClass('selected');
    $(this).addClass('selected');
    var amount = $(this).data('giftcertAmount');
    $amountInput.val(amount);
    $('.giftcert-amount-error .invalid-feedback').html('');
  });
};

// All this copied because updateGiftCertificateLineItem in the base module was called locally to the module...

/**
 * shows giftcert toast message
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data, button) {
  $.spinner().stop();

  $('.minicart').trigger('count:update', data);
  var status = data.success ? 'alert-success' : 'alert-danger';

  if ($('.add-giftcertificate-messages').length === 0) {
      $('body').append('<div class="add-giftcertificate-messages toast-messages"></div>');
  }

  $('.add-giftcertificate-messages').append('<div class="alert text-center add-giftcertificate-alert text-center ' + status + '">' + (data.msg || 'There was an error') + '</div>');

  setTimeout(function () {
      $('.add-giftcertificate-messages').remove();
      if (button) {
          button.removeAttr('disabled');
      }
  }, 5000);
}

function updateGiftCertificateLineItem(data, uuid) {
  var lineItem;
  for (var i = 0; i < data.items.length; i++) {
      if (data.items[i].UUID === uuid) {
          lineItem = data.items[i];
          break;
      }
  }

  if (lineItem) {
      $('.dwfrm_giftCertificate_purchase_fromFirstName-' + uuid + ' span').html(lineItem.senderFirstName);
      $('.dwfrm_giftCertificate_purchase_fromFirstName-' + uuid).data('value', lineItem.senderFirstName);
      $('.dwfrm_giftCertificate_purchase_fromLastName-' + uuid + ' span').html(lineItem.senderLastName);
      $('.dwfrm_giftCertificate_purchase_fromLastName-' + uuid).data('value', lineItem.senderLastName);
      $('.dwfrm_giftCertificate_purchase_recipientFirstName-' + uuid + ' span').html(lineItem.recipientFirstName);
      $('.dwfrm_giftCertificate_purchase_recipientFirstName-' + uuid).data('value', lineItem.recipientFirstName);
      $('.dwfrm_giftCertificate_purchase_recipientLastName-' + uuid + ' span').html(lineItem.recipientLastName);
      $('.dwfrm_giftCertificate_purchase_recipientLastName-' + uuid).data('value', lineItem.recipientLastName);
      $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid + ' span').html(lineItem.recipientEmail);
      $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value', lineItem.recipientEmail);
      $('.dwfrm_giftCertificate_purchase_message-' + uuid + ' span').html(lineItem.message || '');
      $('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title', lineItem.message || '');
      $('.pricing.item-total-' + uuid).html(lineItem.priceTotal.price);
      $('.pricing.item-total-' + uuid).data('value', lineItem.priceTotal.priceValue + '');
  }
}

base.giftCertificateFormSubmission = function() {
  $('body').on('submit', 'form.GiftCertificateForm', function (e) {
      e.preventDefault();

      var form = $(this);
      var amount = form.find('#amount').val();
      if (!amount) {
        var errorElement = $('.giftcert-amount-error .invalid-feedback');
        var errorMessage = errorElement.data('missing');
        errorElement.html(errorMessage);
        return false;
      }

      var isEdit = false;
      var button = form.find('.btn-add-giftcertificate-tocart');
      if (!button || !button.length) {
          button = form.find('.btn-update-giftcertificate-incart');
          isEdit = true;
      } else {
          $('body').trigger('product:beforeAddToCart', this);
      }
      form.parents('.card').spinner().start();

      var url = form.attr('action');
      var queryString = form.serialize() + '&format=ajax';
      if (isEdit) {
          queryString += '&uuid=' + button.data('uuid');
      }
      button.attr('disabled', true);
      recaptcha.check(e, {
          url: url,
          method: 'POST',
          dataType: 'json',
          data: queryString,
          success: function (data) {
              if (data.success) {
                  if (isEdit) {
                      $('#editGiftCertificateLineItemModal').modal('hide');
                      cart.updateCartTotals(data.cartModel);
                      updateGiftCertificateLineItem(data.cartModel, button.data('uuid'));
                      $('body').trigger('cart:update', [data, button.data('uuid')]);
                  } else {
                      $('body').trigger('product:afterAddToCart', data);
                  }
                  form.trigger('reset');
                  formErrors.clearPreviousErrors(form);
                  $('[data-giftcert-amount]').removeClass('selected');
              } else {
                  if (data.redirectUrl) {
                      window.location.href = data.redirectUrl;
                  } else {
                      formValidation(form, data);
                  }
              }

              displayMessage(data, button);
          },
          error: function (err) {
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              }
              displayMessage(err, button);
          }
      });
  })
};

base.init = function () {
  base.giftCertificateFormSubmission();
  base.checkBalanceFormSubmission();
  base.applyGiftCertificateToBasket();
  base.removeGiftCertificatePaymentInstrument();
  base.applyGiftCardAmount();
}

module.exports = base;